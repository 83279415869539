body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 550px;
  overflow: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(/static/media/background.550cd0da.png);
  background-position: center;
}

.App-link {
  color: #61dafb;
}

.top {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 80%;
  padding: 50px 0;
}

.top .logo {
  width: 132px;
}

.top a {
  margin: 10px 20px;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
}

.top a.current {
  border-bottom: 2px solid #FFF;
  padding-bottom: 10px;
}

.top .freeTrial {
  background: #F8FEFC;
  border-radius: 9px;
  color: #000;
  padding: 5px 20px;
}

.freeTrialBeta {
  margin-top: 50px;
}

.feature {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 100px 0 100px 0;
  background: #F4F6F9;
}

.feature .item {
  width: 230px;
  background: #FFFFFF;
  border: 1px solid #C9CDD4;
  border-radius: 3px;
  padding: 20px;
  margin: 20px 20px 0 0;
}

.feature .item .icon {
  width: 70px;
}

.feature .item .title {
  font-size: 24x;
  color: #343434;
  letter-spacing: 2.8px;
  margin-top: 20px;
}

.feature .item .detail {
  font-size: 14x;
  color: #666666;
  letter-spacing: 2.8px;
  margin-top: 30px;
  text-align: left;
}

.functions {
  padding: 50px 70px; 
}

.primary-title {
  font-size: 35px;
  color: #343434;
  letter-spacing: 4px;
}

.second-title {
  font-size: 20px;
  color: #666666;
  letter-spacing: 1.43px;
  margin-top: 15px;
}

.functions .items {
  display: flex;
  margin: 50px 0;
  justify-content: space-around;
  flex-wrap: wrap;
}

.functions .items .item {
  display: flex;
  align-items: center;
  margin: 10px;
}

.functions .items .item img {
  width: 30px;
  margin-right: 10px;
}

.functions .items .item .content {
  font-size: 25px;
  color: #343434;
  letter-spacing: 2.22px;
}

.modules {
  background: #F4F6F9;
  padding: 50px 0;
}

.modules img {
  width: 232px;
  margin: 10px 20px 10px 0;
}

.partner {
  padding: 50px 0 50px 0; 
}

.partner img {
  width: 90%;
  margin-top: 30px;
}

.footer {
  background: #1B2028;
  padding: 50px 20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  color: #FFFFFF;
  font-size: 18px;
}

.footer .title {
  font-size: 28px;
  color: #FFFFFF;
  letter-spacing: 0;
}

.footer .contact {
  text-align: left;
}

.footer .wechat img {
  width: 150px;
  margin-top: 20px;
}

.freetrial .builds {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  justify-content: space-around;
  width: 80%;
}

.freetrial .builds .build {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.freetrial .builds .build img {
  margin-bottom: 20px;
}

.freetrial .introduce {
  padding: 20px 20%;
  color: #666666;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

